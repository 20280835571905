body {
	background: #EDF2F7;	
}

.link {
	color: #4299E1;
	font-weight: 700;
}

.link:hover {
	color: #3182CE;
}